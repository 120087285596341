import React from 'react';


const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
      <img src="/pngwingcom.png" alt="logo" width="50" height="50" />
      </div>
        <div className="navbar-right">
            <h2>
                GREEN TECH FARM
            </h2>
        </div>
    </nav>
  );
};

export default Navbar;


