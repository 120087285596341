import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns'; // Importa el adaptador de fecha
import './Dashboard.css'; // Importa el archivo CSS

import { CategoryScale, LinearScale, TimeScale, LineElement, PointElement } from 'chart.js';

Chart.register(CategoryScale, LinearScale, TimeScale, LineElement, PointElement);

const Dashboard = () => {
  const [sensorData, setSensorData] = useState([]);
  const [latestValues, setLatestValues] = useState([]);

  useEffect(() => {
    const sensorEndpoints = [
        "https://lechugator.onrender.com/luminosity/lu1",
        "https://lechugator.onrender.com/environmenttemperature/environmenttemperature1",
        "https://lechugator.onrender.com/environmenthumidity/environmenthumidity1",
        "https://lechugator.onrender.com/ph/ph1",
        "https://lechugator.onrender.com/waterconductivity/wc1",
        "https://lechugator.onrender.com/waterlevel/waterlevel1",
        "https://lechugator.onrender.com/watertemperature/watertemperature1",
      ];

    const fetchData = async () => {
      try {
        const sensorDataPromises = sensorEndpoints.map((endpoint) =>
          fetch(endpoint).then((response) => response.json())
        );

        const sensorData = await Promise.all(sensorDataPromises);

        setSensorData(sensorData);

        // Obtener los últimos valores de cada sensor
        const latestValues = sensorData.map((data) => {
          const lastIndex = data.length - 1;
          return data[lastIndex];
        });

        setLatestValues(latestValues);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
  }, []);
  const SensorNames = [
    "Luminosidad",
    "Temperatura del Ambiente",
    "Humedad del Ambiente",
    "pH",
    "Conductividad del Agua",
    "Nivel del Agua",
    "Temperatura del Agua",
    ];
  return (

    <div className="dashboard">
      <div className="charts">
        {sensorData.map((data, index) => (
          <div className="chart" key={`chart-${index}`}>
            <h2>Gráfico de {SensorNames[index]} </h2>
            <Line
              data={{
                labels: data.map((item) => item.fecha),
                datasets: [
                  {
                    label: SensorNames[index],
                    data: data.map((item) => ({
                      x: item.fecha, // Usa x para valores de tiempo
                      y: item.valor,
                    })),
                    borderColor: 'rgba(52, 152, 219, 1)', // Color azul
                    backgroundColor: 'rgba(52, 152, 219, 0.2)', // Color azul con transparencia
                  },
                ],
              }}
              options={{
                scales: {
                  x: {
                    type: "time", // Usa type "time" para escalas de tiempo
                  },
                },
              }}
            />
          </div>
        ))}
      </div>
    <div className="latest-values">
        {latestValues.map((value, index) => (
            <div className="sensor-card" key={`sensor-card-${index}`}>
            <h3>{SensorNames[index]}</h3>
            {value ? (
                <div>
                {/* <p>Fecha: {value.fecha}</p> */}
                <p>Valor: {(value.valor).toFixed(2)}</p>
                </div>
            ) : (
                <p>Datos no disponibles</p>
            )}
            </div>
        ))}
        </div>


    </div>
  );
};
export default Dashboard;

// const sensorEndpoints = [
//     "https://lechugator.onrender.com/luminosity/lu1",
//     "https://lechugator.onrender.com/environmenttemperature/environmenttemperature1",
//     "https://lechugator.onrender.com/environmenthumidity/environmenthumidity1",
//     "https://lechugator.onrender.com/ph/wc1",
//     "https://lechugator.onrender.com/waterconductivity/wc1",
//     "https://lechugator.onrender.com/waterlevel/waterlevel1",
//     "https://lechugator.onrender.com/watertemperature/watertemperature1",
//   ];