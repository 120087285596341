import React from 'react';
import ReactDOM from 'react-dom';
import Dashboard from './Dashboard'; // Importa el componente Dashboard
import './Dashboard.css'; // Importa el archivo CSS
import './Navbar.css';
import Navbar from './Navbar';

ReactDOM.render(
  <React.StrictMode>
    <div>
    <Navbar className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="#">Navbar</a>
    </Navbar>

    <Dashboard /> {/* Renderiza el componente Dashboard */}
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
